import {
  Renderer2,
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  Input,
} from '@angular/core';
import { Logout, IdmService } from 'ngx-pijam-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { mixpanelEvent } from './pi.constants';
import { AuthService } from '../auth/login/service/Auth.service';
import { LanguageService } from '../services/language.service';
import { TopicDataService } from '../competition/competition-topics/topic-data.service';
import { ThemeControllerService } from '../services/theme-controller.service';
import { getAssetPath } from 'src/Utility/path-util';
@Component({
  selector: 'pijam-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Output()
  toggleSidebar = new EventEmitter<void>();
  @Input()
  isGreenTheme: boolean = false;
  isLargeScreen: boolean;
  showModal = false;
  name!: string | number | null;
  isAuth: any = false;
  logedOut!: boolean;
  userType!: string | number | null;
  isNavbarCollapsed!: any;
  client!: string | null;
  isSticky: boolean = false;
  competitionId!: number;
  hideAccountButton: boolean = false;
  getAssetPath = getAssetPath

  compLanguages: { [key: number]: { code: string, name: string }[] } = {
    5: [
      { code: 'en', name: 'English' },
      { code: 'hi', name: 'Hindi' },
      { code: 'pu', name: 'Punjabi' },
      { code: 'be', name: 'Bengali' },
      { code: 'or', name: 'Oriya' },
      { code: 'ta', name: 'Tamil' },
      { code: 'ka', name: 'Kannada' }
    ],
    20: [
      { code: 'en', name: 'English' },
      { code: 'hi', name: 'Hindi' },
      { code: 'ma', name: 'Marathi' }
    ],
    8: [
      { code: 'en', name: 'English' },
      { code: 'hi', name: 'Hindi' },
      { code: 'ma', name: 'Marathi' }
    ],
    21: [
      { code: 'en', name: 'English' },
      { code: 'hi', name: 'Hindi' },
    ],
    11: [
      { code: 'en', name: 'English' },
      { code: 'hi', name: 'Hindi' },
    ],
    // 14: [
    //   { code: 'en', name: 'English' },
    //   { code: 'hi', name: 'Hindi' },
    //   { code: 'ma', name: 'Marathi' }
    // ],
    // 9: [
    //   { code: 'en', name: 'English' },
    //   { code: 'hi', name: 'Hindi' },
    //   { code: 'ma', name: 'Marathi' }
    // ]
  };

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private dataService: DataService,
    private logout: Logout,
    private router: Router,
    public idmService: IdmService,
    public languageService: LanguageService,
    public topicDataService: TopicDataService,
    public activatedRoute: ActivatedRoute,
    public themeControllerService: ThemeControllerService
  ) {
    this.isLargeScreen = window.innerWidth > 992;
    this.isAuth = this.authService.isAuthenticated();
    console.log('Specs in the toolbar component :: ', this.hideAccountButton);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isLargeScreen = window.innerWidth > 992;
  }

  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {
  //   const header = document.querySelector('header');
  //   const stickyOffset = header?.offsetTop;

  //   if (stickyOffset!=undefined && window.pageYOffset > stickyOffset) {
  //     this.isSticky = true;
  //   } else {
  //     this.isSticky = false;
  //   }
  // }

  ngOnInit(): void {
    this.setCompetitionId();
    this.authService.isAuth$.subscribe((status: boolean) => {
      this.isAuth = status;
      this.name = this.idmService.get('first_name');
      this.userType = this.idmService.get('user_type');
    });

    this.fetchAndSetClient();
    setTimeout(this.fetchAndSetClient, 2000);
  }

  get currentLanguages() {
    return this.compLanguages[this.competitionId] || [];
  }


  fetchAndSetClient = () => {
    this.client = localStorage.getItem('clientName');
    this.hideAccountButton = this.client === 'samiksha';
    console.log(
      'Client fetched and flag set :: ',
      this.client,
      this.hideAccountButton
    );
  };
  setCompetitionId() {
    this.route.params.subscribe((params) => {
      this.competitionId = params['id'];
    });
  }

  onToggleSidebar() {
    this.toggleSidebar.emit();
  }

  async onLogout() {
    this.closeNavbar();
    await this.logout.execute();
    this.router.navigate(['/landing']);
    this.clearUserData();
  }

  async onLogoutGreenTheme() {
    this.closeNavbar();
    await this.logout.execute();
    this.clearUserData();
    this.redirectUrl();
  }

  private clearUserData() {
    this.authService.logout();
    this.name = '';
    this.userType = '';
    localStorage.clear();
    this.LogoutMixpanel();
    this.idmService.setReferrer(null);
  }

  redirectUrl(): void {
    const competitionId = this.activatedRoute.snapshot.paramMap.get('id');

    const targetUrl = `/competition/${competitionId}`;
    const currentUrl = this.router.url.split('?')[0];
    if (currentUrl === targetUrl) {
      window.location.reload();
    } else {
      this.router.navigate([targetUrl], {
        queryParams: { ...this.topicDataService.queryParams },
      });
    }
  }

  LogoutMixpanel() {
    mixpanelEvent.checkpoint('USER_LOGGED_OUT');
  }

  onCloseModal() {
    this.dataService.changeBoolean(false);
    const numberField: any = document.getElementById('phoneNumberInput');
    const cleanReqOtp: any = document.getElementById('onCloseModalReqopt');
    numberField.value = '';
    cleanReqOtp.click();
  }

  onSignInClick() {
    this.setReferrer();
    this.closeNavbar();
  }

  setReferrer() {
    const currentPath = this.router.url;
    const existingReferrer = this.idmService.getReferrer();

    if (existingReferrer == null && !currentPath.includes('/auth')) {
      this.idmService.setReferrer(currentPath);
      return;
    }
  }

  closeNavbar() {
    const navbarCollapse: any = document.querySelector('.navbar-collapse');
    if (navbarCollapse?.classList.contains('show')) {
      this.renderer.removeClass(navbarCollapse, 'show');
    }
  }
  goToLanding() {
    this.router.navigate(['/landing']);
  }

  signup() {
    this.closeModal();
    this.router.navigate(['/auth']);
  }
  closeModal() {
    const tempButton: any = document.getElementById('closeModalButton');
    tempButton.click();
  }

  homeNavigation(): void {
    if (this.client == 'samiksha') {
      this.router.navigate([`/client/${this.client}`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  selectLanguage(language: string): void {
    this.languageService.updateLanguage(language);
    console.log(`Language selected: ${language}`);
  }

  getLogoUrl(): string {
    return this.isGreenTheme
      ? getAssetPath('pijam-green-logo.svg')
      : getAssetPath('logo.svg');
  }

}
