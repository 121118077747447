import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThemeControllerService {
  private renderer: Renderer2;
  private theme: string = 'default-theme';
  private routeThemeMapping: { [key: string]: string } = {
    '/competition/5': 'theme-green',
    '/competition/14': 'theme-light-blue',
    '/competition/9': 'theme-light-blue',
    '/competition/15': 'theme-green',
    '/competition/6': 'theme-green',
    '/competition/7': 'theme-green',
    '/client/samiksha': 'theme-green',
    '/competition/19': 'theme-green',
    '/competition/10': 'theme-light-blue',
    '/competition/11': 'theme-brown',
    '/competition/8': 'theme-red',
    '/competition/20': 'theme-red',
    '/competition/21': 'theme-brown',
    '/competition/2': 'theme-green',
  };
  private defaultTheme: string = 'theme-default';
  private competitionDefaultTheme: string = 'theme-blue';

  constructor(rendererFactory: RendererFactory2, private router: Router) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.applyThemeForRoute(event.urlAfterRedirects);
      });
  }

  private applyThemeForRoute(route: string) {
    const theme = this.getThemeForRoute(route) || this.defaultTheme;
    this.setTheme(theme);
  }

  private getThemeForRoute(route: string): string | undefined {
    for (const [key, value] of Object.entries(this.routeThemeMapping)) {
      if (route.startsWith(key)) {
        return value;
      }
    }
    if (route.startsWith('/competition')) {
      return this.competitionDefaultTheme;
    }
    return undefined;
  }

  private setTheme(theme: string) {
    this.renderer.setAttribute(document.body, 'data-theme', theme);
    this.theme = theme;
  }

  getTheme(): string {
    return this.theme;
  }
}
